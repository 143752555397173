import React from "react";
import Color from "color";
import { Modal, Button, Menu, Icon, Input, Loader } from "semantic-ui-react";
import { observer, inject } from "mobx-react";
import { getType, isValidReference } from "mobx-state-tree";
import { withTranslation } from "react-i18next";
import { arrayFilter } from "../../../helpers/calendar.js";
import ResMapDetail from "./resMapDetail";

class ReverseChooser extends React.Component {
	constructor(props) {
		super(props);
		this.state = ReverseChooser.initialState;
		this.onScroll = this.onScroll.bind(this);
	}

	static initialState = {
		filter: "",
		selected: [],
		selectedClass: "",
		activeMap: "",
		initiallySelected: [],
		marker: false,
		information: [],
		jobMap: new Map(),
		absences: {}
	};

	componentDidUpdate(prevProps) {
		if (!this.props.data && prevProps.data) {
			this.setState(ReverseChooser.initialState);
		} else if (this.props.data && !prevProps.data) {
			setTimeout(x => this.setState(this.load()), 5);
		}
	}

	static jobOverlaps(x, y) {
		return x.start <= y.end && y.start <= x.end;
	}

	load() {
		const { resource, dates } = this.props.data;

		//get previous position
		const isGroup = getType(resource).name === "ResGroup";
		
		let resGroupHuman = false;
		const member_humans = [];
		if (isGroup) {			
			for (let member of this.props.data.resource._members.values()) {
				member_humans.push(member._resclass.human); 				 
			}
			resGroupHuman = member_humans.every( (val, i, arr) => val === arr[0] );
		}
		let absences = isGroup || !this.props.app.ui.rights.has("PLAN_VACATION") ? {} : resource.getAbsencesByDates(dates);

		let absences_temp = [];
		let absencesGroup = [];
		if( isGroup && this.props.app.ui.rights.has("PLAN_VACATION") ){
			for (let member of resource._members.values()) {
				absences_temp = member.getAbsencesByDates(dates);				 
				let l;
				for (const [key, value] of Object.entries(absences_temp)) {  
					// eslint-disable-next-line
					for (const [k, v] of Object.entries(value)) {
						if (typeof absencesGroup[key] !== "undefined") {
							l = Object.keys(absencesGroup[key]).length;
							absencesGroup[key][l] = v;
						} else {					
							absencesGroup[key] = [ v ];
						}
					}					
				} 				
			}
			absences = absencesGroup;			 
		} 		

		const absenceTypes = isGroup || !this.props.app.ui.rights.has("PLAN_VACATION")
			? resGroupHuman && member_humans.length ? this.props.app.basedata.getAbsenceTypes(member_humans[0]) : []
			: this.props.app.basedata.getAbsenceTypes(resource.resclass.human);

		const jobs = this.props.app.projects.getJobsByDates(dates);

		const projects = [];
		const jobMap = new Map();
		const selection = [];

		//merge jobs by process
		for (let job of jobs) {
			if (!jobMap.has(job.process.id)) {
				jobMap.set(job.process.id, {
					jobs: [],
					dispatched: 0
				});
				projects.push({
					name: job.project.fullName,
					processname: job.process.name,
					icon: job.process.template.icon,
					color: job.process.template.color,
					project: true,
					template: job.process.template.name,
					id: job.process.id
				});
			}
			const d = jobMap.get(job.process.id);
			d.jobs.push(job);

			if (
				isGroup
					? job.hasGroup(resource.id)
					: job.hasResource(resource.id)
			)
				d.dispatched++;
			jobMap.set(job.process.id, d);
		}
		//console.log(jobMap);
		for (let [pid, data] of jobMap) {
			if (data.dispatched >= data.jobs.length)
				selection.push("project#" + pid);
		}
		for (let absenceId in absences) {
			if (absences[absenceId].length >= dates.length)
				selection.push("absence#" + absenceId);
		}

		return {
			jobMap,
			absences,
			initiallySelected: selection.slice(),
			selected: selection,
			information: [
				{
					key: "absence",
					name: this.props.t("reverseChooser.absences"),
					content: absenceTypes
				},
				{
					key: "project",
					name: this.props.t("reverseChooser.projects"),
					content: projects
				}
			]
		};
	}

	onScroll() {
		this.setState(prev => {
			if (prev.marker !== false)
				return {
					marker: false
				};
			else return null;
		});
	}

	componentDidMount() {}

	changeFilter(filter) {
		this.setState({ filter });
	}

	selectClass(cls) {
		this.setState(prevState => {
			return {
				selectedClass: prevState.selectedClass === "" ? cls : "",
				activeMap: ""
			};
		});
	}

	close() {
		this.props.app.ui.closeReverseChooser();
	}

	save() {
		let add = this.state.selected.filter(
			x => !this.state.initiallySelected.includes(x)
		);
		let remove = this.state.initiallySelected.filter(
			x => !this.state.selected.includes(x)
		);

		const isGroup = getType(this.props.data.resource).name === "ResGroup";

		for (let key of add) {
			const [type, id] = key.split("#");
			if (type === "project") {
				for (let job of this.state.jobMap.get(id).jobs) {
					if (isGroup) {
						for (let member of this.props.data.resource._members.values()) {
							if (
								!isValidReference(() => member) ||
								member.deleted
							)
								continue;
							job.dispose(
								member,
								"ADD",
								this.props.data.resource.id
							);
						}
					} else job.dispose(this.props.data.resource, "ADD");
				}
			} else if (!isGroup && type === "absence") {
				this.props.data.resource.addAbsences(id, this.props.data.dates);
			} else if (isGroup && type === "absence") {
				for (let member of this.props.data.resource._members.values()) {
					if (
						!isValidReference(() => member) ||
						member.deleted
					)
						continue; 
						member.addAbsences(id, this.props.data.dates);
				}
			}
		}

		for (let key of remove) {
			const [type, id] = key.split("#");
			if (type === "project") {
				for (let job of this.state.jobMap.get(id).jobs) {
					if (isGroup) {
						for (let member of this.props.data.resource._members.values()) {
							if (
								!isValidReference(() => member) ||
								member.deleted
							)
								continue;
							job.dispose(
								member,
								"REMOVE",
								this.props.data.resource.id
							);
						}
					} else job.dispose(this.props.data.resource, "REMOVE");
				}
			} else if (!isGroup && type === "absence") {
				for (let absence of this.state.absences[id]) {
					absence.delete();
				}
			} else if (isGroup && type === "absence") {
				//for (let member of this.props.data.resource._members.values()) {					 
					for (let absence of this.state.absences[id]) {
						absence.delete();
					}
				//}
			}
		}

		this.close();
	}

	toggle(e, resClass, resource) {
		e.persist();
		const markpos =
			e.clientX -
			e.target.closest(".resc_wrapper").getBoundingClientRect().x;
		const key = resClass + "#" + resource;
		this.setState(prevState => {
			let selected = prevState.selected;
			let activeMap = "";
			let marker = false;
			if (prevState.selected.includes(key)) {
				selected = selected.filter(x => x !== key);
			} else {
				activeMap = key;
				marker = markpos;
				selected.push(key);
			}
			return {
				selected,
				activeMap,
				marker
			};
		});
	}

	render() {
		if (!this.props.data) return null;
		const filter = arrayFilter(this.state.filter); 
		const compactmode =
			false &&
			this.state.filter === "" &&
			this.state.selectedClass === "";
				return (
			<Modal
				centered={false}
				className="reverseChooserModal"
				open={this.props.data !== false}
				onClose={e => this.props.close()}
			>
				{this.state.information === false ? (
					<Loader size="large" />
				) : (
					<React.Fragment>
						<Menu>
							<Menu.Item header>
								{this.props.t("reverseChooser.heading")}
							</Menu.Item>
							<Menu.Item>
								<Input
									className="icon"
									icon="search"
									placeholder={this.props.t("filter")}
									value={this.state.filter}
									onChange={(e, { value }) =>
										this.changeFilter(value)
									}
								/>
							</Menu.Item>
							<Menu.Menu position="right">
								<Menu.Item
									name="close it"
									onClick={e => this.close()}
								>
									<Icon name="close" />{" "}
									{this.props.t("cancel")}
								</Menu.Item>
								<Menu.Item
									name="save it"
									onClick={e => this.save()}
								>
									<Icon name="check" /> {this.props.t("ok")}
								</Menu.Item>
							</Menu.Menu>
						</Menu>
						<Modal.Content>
							<div className="reverseChooserWrapper">
								{this.state.information.map(c => {
									if (
										this.state.selectedClass !== "" &&
										this.state.selectedClass !== c.key
									)
										return null;
									const order = [];
									let resMapInfo = false;
									let rescomps = c.content
										.map(r => {
											if (!filter([r.name, ("processname" in r) ? r.processname : ""])) return null;
											order.push(c.key + "#" + r.id);
											if (
												this.state.activeMap ===
												c.key + "#" + r.id
											)
												resMapInfo =
													"resMapData" in r
														? r.resMapData
														: false;
											return (
												<div
													key={r.id}
													className={
														"resc_res" +
														(this.state.selected.includes(
															c.key + "#" + r.id
														)
															? " marked"
															: "")
													}
													onClick={e =>
														this.toggle(
															e,
															c.key,
															r.id
														)
													}
												>
													{r.project ? (
														<React.Fragment>
															<div
																className={
																	"resc_image reverseresc" +
																	(r.icon
																		? " picon picon-" +
																		  r.icon
																		: "")
																}
																style={{
																	color:
																		"rgba(" +
																		(Color(
																			r.color
																		).isLight()
																			? "0,0,0"
																			: "255,255,255") +
																		",0.75)",
																	backgroundColor:
																		r.color
																}}
															>
																{r.icon
																	? ""
																	: r.template
																			.substr(
																				0,
																				1
																			)
																			.toUpperCase()}
															</div>
															<div className="resc_name">
																{r.name}
															</div>
															<div className="resc_name subname">
																{r.processname}
															</div>
														</React.Fragment>
													) : (
														<React.Fragment>
															<div
																className={
																	"resc_image reverseresc" +
																	(r.division
																		? ""
																		: " undivided")
																}
																style={{
																	color:
																		"rgba(" +
																		(Color(
																			r.color
																		).isLight()
																			? "0,0,0"
																			: "255,255,255") +
																		",0.75)",
																	backgroundColor:
																		r.color
																}}
															>
																{r.shortname}
															</div>
															<div className="resc_name">
																{r.name}
															</div>
														</React.Fragment>
													)}
												</div>
											);
										})
										.filter(x => x);
									if (!rescomps.length) {
										return null;
									}
									let pos;
									if (resMapInfo) {
										pos = this.state.activeMap
											? order.indexOf(
													this.state.activeMap
											  )
											: -1;
										if (!compactmode && pos > -1) {
											const insertPos =
												Math.floor(pos / 5) * 5 + 5;
											rescomps.splice(
												insertPos,
												0,
												<ResMapDetail
													key="rema"
													marker={this.state.marker}
													resourceKey={
														this.state.activeMap
													}
													information={resMapInfo}
												/>
											);
										}
									}

									return (
										<div
											key={c.key}
											className={
												"resc_classwrapper" +
												(compactmode
													? " compactmode"
													: "")
											}
										>
											<div className="resc_classname">
												<Button
													color={
														this.state
															.selectedClass ===
														c.key
															? "grey"
															: null
													}
													onClick={() =>
														this.selectClass(c.key)
													}
													icon
													labelPosition="left"
												>
													{c.name}
													<Icon name="filter" />
												</Button>
											</div>
											<div
												className="resc_wrapper"
												onScroll={this.onScroll}
											>
												{rescomps}
											</div>
											{resMapInfo &&
											compactmode &&
											pos > -1 ? (
												<ResMapDetail
													key="rema"
													marker={this.state.marker}
													resourceKey={
														this.state.activeMap
													}
													information={resMapInfo}
												/>
											) : null}
										</div>
									);
								})}
							</div>
						</Modal.Content>
					</React.Fragment>
				)}
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(ReverseChooser)));
