import {
	types,
	applySnapshot,
	isValidReference,
	getSnapshot
} from "mobx-state-tree";
import {
	getSupplierShortname,
	getMixtureShortname
} from "../helpers/shortnames";
import uuid from "uuid";
import omitBy from "lodash.omitby";



export const Supplier = types
	.model("Supplier", {
		id: types.identifier,
		name: types.string,
		shortname: types.optional(types.string, ""),
		deleted: types.optional(types.boolean, false),
		updatedAt: types.optional(types.Date, () => new Date())
	})
	.views(self => ({
		get safeShortname() {
			if (self.shortname !== "") return self.shortname;
			return getSupplierShortname(self.name);
		}
	}))
	.actions(self => ({}));

//todo: onSnapshot


export const ProjectPosition = types
	.model("ProjectPosition", {
		lat: types.number,
		lng: types.number,
		zoom: types.integer
	})
	.views(self => ({}))
	.actions(self => ({}));


export const Material = types
	.model("Material", {
		id: types.identifier,
		_supplier: types.maybeNull(types.reference(Supplier)),
		name: types.string,
		shortname: types.optional(types.string, ""),
		number: types.optional(types.string, ""),
		deleted: types.optional(types.boolean, false),
		autoAdded: types.optional(types.boolean, false),
		unit: types.string,
		updatedAt: types.optional(types.Date, () => new Date())
	})
	.views(self => ({
		get safeShortname() {
			let number = self.number === "" ? "" : ("("+self.number+")");
			if (self.shortname !== "") return self.shortname+number;
			return getMixtureShortname(self.name)+number;
		},
		get fullname() {
			if (self.number === "") return self.name;
			return self.name+" ("+self.number+")";
		},
		get supplier() {
			if (isValidReference(() => self._supplier)) return self._supplier;
			return { name: "?", id: false };
		}
	}))
	.actions(self => ({}));

export const Person = types
	.model("Person", {
		id: types.identifier,
		deleted: types.optional(types.boolean, false),
		name: types.string,
		type: types.enumeration(["BAULEITER", "POLIER", "DISPO"]),
		username: types.optional(types.string,""),
		updatedAt: types.optional(types.Date, () => new Date())
	})
	.views(self => ({}))
	.actions(self => ({}));

export const TruckType = types
	.model("TruckType", {
		id: types.identifier,
		deleted: types.optional(types.boolean, false),
		name: types.string,
		updatedAt: types.optional(types.Date, () => new Date())
	})
	.views(self => ({}))
	.actions(self => ({}));

export const Company = types
	.model("Company", {
		id: types.identifier,
		deleted: types.optional(types.boolean, false),
		name: types.string,
		updatedAt: types.optional(types.Date, () => new Date())
	})
	.views(self => ({}))
	.actions(self => ({}));

export const AbsenceType = types
	.model("AbsenceType", {
		id: types.identifier,
		deleted: types.optional(types.boolean, false),
		name: types.string,
		shortname: types.optional(types.string, ""),
		icon: types.optional(types.string, ""),
		color: types.string,
		types: types.enumeration(["HUMANS", "MACHINES", "BOTH"]),
		updatedAt: types.optional(types.Date, () => new Date())
	})
	.views(self => ({
		get safeShortname() {
			if (self.shortname !== "") return self.shortname;
			return self.name.substr(0, 1);
		}
	}))
	.actions(self => ({}));

export const ProcessTemplate = types
	.model("ProcessTemplate", {
		id: types.identifier,
		name: types.string,
		deleted: types.optional(types.boolean, false),
		color: types.string,
		type: types.enumeration([
			"PROCESS",
			"ASPHALT",
			"EARTHWORKS",
			"MILLING"
		]),
		icon: types.maybeNull(types.string),
		updatedAt: types.optional(types.Date, () => new Date())
	})
	.views(self => ({}))
	.actions(self => ({}));

export const Day = types
	.model("Day", {
		id: types.identifier,
		date: types.string,
		deleted: types.optional(types.boolean, false),
		updatedAt: types.optional(types.Date, () => new Date())
	})
	.views(self => ({}))
	.actions(self => ({
		delete() {
			self.deleted = true;
			self.updatedAt = new Date();
		}
	}));

const BaseDataStore = types
	.model("BaseDataStore", {
		exceptions: types.map(Day),
		holidays: types.map(Day),
		workDays: types.optional(types.array(types.boolean), [
			true,
			true,
			true,
			true,
			true,
			false,
			false
		]),
		processTemplates: types.map(ProcessTemplate),
		materials: types.map(Material),
		suppliers: types.map(Supplier),
		persons: types.map(Person),
		absenceTypes: types.map(AbsenceType),
		truckTypes: types.map(TruckType),
		company: types.maybeNull(Company)
	})
	.views(self => ({
		getProcessTemplateByName(name) {},
		getRandom(x, random) {
			const resList = [];
			for (let res of self[x].values()) {
				resList.push(res);
			}
			return resList[Math.floor(random * resList.length)];
		},
		getAbsenceTypes(human) {
			const out = [];
			for (let absence of self.absenceTypes.values()) {
				if (absence.deleted) continue;
				if (
					(human && absence.types === "MACHINES") ||
					(!human && absence.types === "HUMANS")
				)
					continue;
				out.push({
					id: absence.id,
					name: absence.name,
					shortname: absence.safeShortname,
					color: absence.color,
					division: false
				});
			}
			return out;
		},
		checkWorkingDay(momentObj, changeRight=false) {
			let date = momentObj.format("YYYY-MM-DD");
			let isHoliday = Array.from(self.holidays.values()).find(
				x => x.date === date && !x.deleted
			);
			let isException = Array.from(self.exceptions.values()).find(
				x => x.date === date && !x.deleted
			);
			let isWorkingDay = true;
			try {
				isWorkingDay = self.workDays[momentObj.isoWeekday() - 1];
			} catch (e) {}

			let key =
				(isWorkingDay ? 1 : 0) +
				(isHoliday ? 2 : 0) +
				(isException ? 4 : 0);

			switch (key) {
				case 4:
				case 6:
				case 7:
					return {
						state: true,
						changeFn: changeRight ? () => self.deleteException(isException.id) : null
					};
				case 5:
					return {
						state: true,
						changeFn: changeRight ? () => {
							self.deleteException(isException.id);
							self.addHoliday(date);
						}: null
					};
				case 0:
				case 2:
				case 3:
					return {
						state: false,
						changeFn: changeRight ? () => self.addException(date): null
					};
				case 1:
					return {
						state: true,
						changeFn: changeRight ? () => self.addHoliday(date) : null
					};
				default:
					return {
						state: false,
						changeFn: null
					};
			}
		}
	}))
	.actions(self => ({
		collectChanges(lastSave) {
			const collections = [
				"exceptions",
				"holidays",
				"truckTypes",
				"suppliers",
				"processTemplates",
				"persons",
				"absenceTypes",
				"materials"
			];
			const out = {};
			for (let collectionName of collections) {
				const collection = self[collectionName];
				for (let data of collection.values()) {
					if (data.updatedAt < lastSave) continue;
					if (!(collectionName in out))
						out[collectionName] = [];
					out[collectionName].push(
						omitBy(
							JSON.parse(JSON.stringify(getSnapshot(data))),
							(value, key) => key.startsWith("$")
						)
					);
				}
			}
			return out;
		},
		deleteException(key) {
			if (self.exceptions.has(key)) self.exceptions.get(key).delete();
		},
		addException(date) {
			const id = uuid.v4();
			self.exceptions.set(id, {
				id: id,
				date: date
			});
		},
		addHoliday(date) {
			const id = uuid.v4();
			self.holidays.set(id, {
				id: id,
				date: date
			});
		},
		deleteHoliday(key) {
		
			if (self.holidays.has(key)) self.holidays.get(key).delete();
		},
		setTemplates(tmp) {
			applySnapshot(self.processTemplates, tmp);
		},
		setPersons(tmp) {
			applySnapshot(self.persons, tmp);
		},
		setMaterials(tmp) {
			applySnapshot(self.materials, tmp);
		},
		setSuppliers(tmp) {
			applySnapshot(self.suppliers, tmp);
		},
		setTruckTypes(tmp) {
			applySnapshot(self.truckTypes, tmp);
		},
		updateWorkDays(data) {
			self.workDays = data;
		},
		update(collection, data, registerRefs) {
			for (let d of data) {
				if (collection.has(d.id)) {
					const originalData = collection.get(d.id);
					if (originalData.updatedAt >= new Date(d.updatedAt))
						continue;
					d = Object.assign(
						JSON.parse(JSON.stringify(getSnapshot(originalData))),
						d
					);
				}
				try{
					collection.set(d.id, d);
					if (registerRefs) collection.get(d.id).registerReferences();
				} catch (e){
					console.log(e);
				}
			}
		},
		updateExceptions(data) {
			self.update(self.exceptions, data, false);
		},
		updateHolidays(data) {
			self.update(self.holidays, data, false);
		},
		updateTruckTypes(data) {
			self.update(self.truckTypes, data, false);
		},
		updateCompany(data){
			self.company = data;
		},
		updateSuppliers(data) {
			self.update(self.suppliers, data, false);
		},
		updateProcessTemplates(data) {
			self.update(self.processTemplates, data, false);
		},
		updatePersons(data) {
			self.update(self.persons, data, false);
		},
		updateAbsenceTypes(data) {
			self.update(self.absenceTypes, data, false);
		},
		updateMaterials(data) {
			self.update(self.materials, data, false);
		}
	}));

export default BaseDataStore;
